<template>
  <div>
    <div class="height-100" style="background-color: #F8F8F8;padding: 1.7rem;margin-top: 1rem;" v-if="position == 0">
      <van-form @submit="onNext">
        <div>
          <span style="margin-top: 0.6rem;font-weight: 600;">手机号 </span>
          <div style="border-radius: 2rem;margin-top: 1rem; padding: 1.1rem 1rem;background-color: white;">{{ nickname }}
          </div>
          <p style="margin-top: 1.7rem;font-weight: 600;">验证码 </p>
          <van-field style="border-radius: 1.5rem;margin-top: 1rem; padding: 1rem;" label-width="3rem" center clearable
            v-model="smsCode" placeholder="输入验证码" :rules="[{ required: true }]">
            <template #button>
              <div style="display: flex;flex-direction: row; align-items: center;">
                <div style="background-color:#D0D0D0; height: 1.5rem;width: 0.02rem; "></div>
                <div v-if="countdown" style="color:#D33B2E;font-size: 1rem;margin-left: 0.5rem;" @click="getCode">{{ text
                }}</div>
                <div v-else style="color:#D33B2E;font-size: 1rem;margin-left: 0.5rem;">{{ count }}秒</div>
              </div>
            </template>
          </van-field>
        </div>
        <div>
          <van-button class="foot-btn">确定</van-button>
        </div>
      </van-form>
    </div>
    <div style="background-color: #FFF; height: 100vh;padding: 1.7rem;" v-if="position == 1">

      <div style="font-size: 1.7rem;margin-top: 2.8rem;"> 设置密码</div>
      <div style="margin-top: 0.85rem;"> 请输入您设定的6位数字密码</div>
      <van-password-input style="margin-top: 2.85rem;" :value="password" gutter="0.5rem" :focused="showKeyboard"
         @focus="showKeyboard = true" />
      <div>
        <van-button class="foot-btn" @click="onSubmit">下一步</van-button>
      </div>

    </div>
    <div style="background-color: #FFF; height: 100vh;padding: 1.7rem;" v-if="position == 2">
      <div style="font-size: 1.7rem;margin-top: 2.8rem;"> 确认密码</div>
      <div v-if="noEqual" style="margin-top: 0.85rem;color: #D33B2E;letter-spacing: 0;">您输入的密码和您第一次输入的不一致，请确认</div>
      <div v-else style="margin-top: 0.85rem;"> 请再次输入您设定的6位数字密码</div>
      <van-password-input :class="noEqual ? 'error' : ''" style="margin-top: 2.85rem;" :value="passwordagain"
        gutter="0.5rem" :focused="showKeyboard2" @focus="showKeyboard2 = true" />
      <div>
        <van-button class="foot-btn" @click="onSubmit2">确定</van-button>
      </div>
    </div>
    <!-- 数字键盘 -->
    <van-number-keyboard v-model="password" :show="showKeyboard" @blur="showKeyboard = false" />
    <van-number-keyboard v-model="passwordagain" :show="showKeyboard2" @blur="showKeyboard2 = false" />
  </div>
</template>
<script>
export default {
  name: '',
  data() {
    return {
      position: 0,
      phone: '13071195611',
      smsCode: null,
      count: "",//60秒倒计时
      text: "获取验证码",
      countdown: true,//验证码的状态，默认显示发送验证码
      password: undefined,
      showKeyboard: false,
      passwordagain: undefined,
      inputtype1: 'password',
      inputtype2: 'password',
      pattern: /^\d{6}$/,
      userId: '',
      isSetHistroy: false,
      nickname: '',

      showKeyboard2: false,
      noEqual: false,
      Fromvnode: null
    }
  },
  created() { },
  components: {},
  methods: {

    onNext() {
      if (!this.smsCode) {
        this.$Toast('请输入验证码');
        return;
      }
      this.position = this.position + 1
    },
    onSubmit() {
      if (!this.password || this.password.length < 6) {
        this.$Toast('请输入密码');
        return;
      }
      this.position = this.position + 1
    },
    onSubmit2() {
      if (!this.passwordagain || this.passwordagain.length < 6) {
        this.$Toast('请输入密码');
        return;
      }
      if (this.password.slice(0, 6) != this.passwordagain.slice(0, 6)) {
        this.noEqual = true
        return;
      }
      let form = {
        code: this.smsCode,
        payPwd: this.password.slice(0, 6),
        userId: this.userId
      }
      if (this.isSetHistroy) {
        this.$api
          .pwdUpdate(form)
          .then((res) => {
            if (res.code == 0) {
              this.position = 0
              this.$router.back();
              this.$Toast.success('更新成功')
            } else {
              this.$Toast(res.msg)
            }
          })
          .catch((err) => {
          });
      } else
        this.$api
          .pwdAdd(form)
          .then((res) => {
            if (res.code == 0) {
              this.position = 0
              this.$router.back();
              this.$Toast.success('添加成功')
            } else {
              this.$Toast(res.msg)
            }
          })
          .catch((err) => {
          });
    },

    getCode() {
      this.$api
        .sendPaySms(this.phone)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.changeButton()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeButton() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.countdown = false;
        this.text = "重新发送"
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.countdown = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },

    pwdCheck() {
      this.$api
        .pwdCheck()
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.isSetHistroy = res.data
        })
        .catch((err) => {
        });
    },
    goBack() {
      if (this.position > 0) {
        this.position -= 1
        history.pushState(null, null, document.URL);
      } else {
        window.history.back();
      }
    },
  },
  watch: {
    position(value) {
      if (value == 0) {
        this.$route.meta.title = '验证身份'
        this.showKeyboard = false
        this.showKeyboard2 = false
        this.password = ''
      } else if (value == 1) {
        this.$route.meta.title = '设置密码'
        this.showKeyboard = true
        this.showKeyboard2 = false
      } else if (value == 2) {
        this.$route.meta.title = '确认密码'
        this.showKeyboard = false
        this.showKeyboard2 = true
        this.passwordagain = ''
        this.noEqual = false
      }
      document.title = this.$route.meta.title
    },
    password(value) {
      if (value.length > 6) {
        this.password = this.password.slice(0, 6)
      }
    },
    passwordagain(value) {
      if (value.length > 6) {
        this.passwordagain = this.passwordagain.slice(0, 6)
      }
    }
  },
  mounted() {
    this.userId = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).id : ''
    this.phone = localStorage.getItem('loginphone')
    this.nickname = this.$utils.formPhone(this.phone);
    this.pwdCheck()
    if (window.history && window.history.pushState) {
      // 向历史记录中插入了当前页
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.Fromvnode = from
  //   })
  // },
  // beforeRouteLeave(to, from, next) {
  //   if (to.path !== '/Cashier') {
  //     this.Fromvnode.meta.keepAlive = false
  //   }
  //   next()
  // }
}

</script>
<style  lang="scss" scoped>
.selectstyle {
  flex: 1;
  height: 3rem;
  min-height: 3rem;
  align-items: center;
  color: #d33b2e;
  background-color: bisque;
  display: flex;
  justify-content: center;
}

.nomalstyle {
  flex: 1;
  height: 3rem;
  min-height: 3rem;
  align-items: center;
  color: #808080;
  background-color: bisque;
  display: flex;
  justify-content: center;
}

.foot-btn {
  width: 100%;
  margin-top: 4.57rem;
  height: 2.8rem;
  text-align: center;
  line-height: 2.8rem;
  border-radius: 2rem;
  color: #fff;
  background-image: linear-gradient(left, #ffc585 0%, #d59a54 100%);
  // box-shadow: 0px 0px 3px rgba(230, 149, 142, 0.6);
  font-size: 1rem;
}

.buttonsty {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.1rem;
  border-radius: 1rem;
  color: #d59a54;
}

.van-password-input__security li {
  border: 1px #e8e8e8 solid;
  border-radius: 0.5rem;
  height: 3rem;
}

.error {
  ::v-deep.van-password-input__security li {
    border: 1px #d33b2e solid;
    border-radius: 0.5rem;
    height: 3rem;
  }
}

.van-password-input {
  margin: 0 !important;
  // -webkit-user-select: auto !important;
  // user-select: auto !important;
}

// .van-cell {
//   padding-left: 0 !important;

//   width: 100%;
// }

// .van-cell:after {
//   border-bottom: 1px solid white;
// }
</style>